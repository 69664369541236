import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { add } from "date-fns";
import { combine, map, pipe, scan, skipWhile } from "wonka";
import { notFalsy, Override } from "../types";
import { eventsToAppointments } from "../utils/appointments";
import { EventColor } from "./EventMetaTypes";
import { Event, ReclaimEventType } from "./Events";
import { IncludeProject } from "./Projects";
import { NotificationSyncStatus, TransformDomain } from "./types";

export type Appointment = Override<
  AppointmentModel,
  {
    id: string;
    title: string;
    description?: string;
    startDate: Date;
    endDate: Date;
    color: EventColor;
    isShaded?: boolean;

    pinned: boolean;
    habitId?: number;
    taskId?: number;
    reclaimEvent?: boolean;
    event: Event;
    preAssistEvent?: Appointment;
    postAssistEvent?: Appointment;

    previousEventHasAssist?: boolean;
    previousEvent?: Appointment;

    formerTitleStatus: "none" | "check" | "shield" | "free" | "locked";
    hasMore: boolean;
    count?: number;
    isAwaitingChange: boolean;

    allDay: boolean;
  }
>;

export class AppointmentsDomain extends TransformDomain<Appointment> {
  resource = "Appointment";
  cacheKey = "appointments";
  pk = "id";

  listAndWatch$$ = (start: Date, end: Date) => {
    const listArgs = [
      add(start, { weeks: -1 }),
      add(end, { days: 1, weeks: 1 }),
      100,
      undefined,
      undefined,
      undefined,
      IncludeProject.Id,
      true,
    ] as const;

    return pipe(
      combine(this.client.events.listAndWatch$$(...listArgs), TransformDomain.assistStatus$),
      map(([events, status]) => {
        const loading = !!status && NotificationSyncStatus.SyncingServer === status;

        if (!events || !Array.isArray(events)) return [];
        return eventsToAppointments(events.filter(notFalsy)).map((a) => {
          if (loading && ReclaimEventType.User !== a.event.reclaimEventType) a.isAwaitingChange = true;
          return a;
        });
      }),
      scan(
        (acc, val) => {
          const allWaiting =
            val.every((a) => !!a.isAwaitingChange || ReclaimEventType.User !== a.event.reclaimEventType) || false;

          if (!!allWaiting && !acc.isAwaiting) return { isAwaiting: true, wasAwaiting: false, data: val || [] };
          if (!!allWaiting && !!acc.isAwaiting) return { isAwaiting: true, wasAwaiting: true, data: val || [] };
          if (!allWaiting && !!acc.isAwaiting) return { isAwaiting: false, wasAwaiting: false, data: val || [] };

          return { isAwaiting: false, wasAwaiting: false, data: val || [] };
        },
        {
          isAwaiting: false,
          wasAwaiting: false,
          data: [] as Appointment[],
        }
      ),
      skipWhile((r) => !!r.wasAwaiting),
      map((r) => r.data)
    );
  };
}
